@use "sass:color";
@import "../../../components/theme/index";

.location {
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 34px;
  line-height: 50px;
  cursor: pointer;
  user-select: none;
  border: solid 1px $alto;
  margin: 5px 15px;
  min-width: 46%;
  flex: 48%;
  font-size: 20px;

  @include respond-to(tablets) {
    font-size: 24px;
  }

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--checked {
    border-color: transparent;
    background: $grey;
  }

  &--checked::before {
    content: "";
    position: absolute;
    display: block;
    top: -1px;
    left: -1px;
    bottom: -1px;
    pointer-events: none;
    width: 7px;
    background-color: $black;
  }

  &:hover:not(&--checked) {
    background-color: color.adjust($white, $lightness: -2%);
  }

  &:active:not(&--checked) {
    background-color: color.adjust($white, $lightness: -4%);
  }
}
